<template flex>
  <div class="login d-flex justify-center">
    <v-main class="d-flex">
        <v-layout fluid flex flex-wrap justify-center>
          <v-flex d-flex xs12 sm5 align-center elevation-0 class="white">
            <v-card flat class="v-login-box rounded-0" width="100%">

                <div class="d-flex mb-16 pb-3 back-to-screen">
                  <a href="/login" class="text-decoration-none black--text">
                    <v-icon medium left class="black--text"> arrow_back </v-icon> Back
                  </a>
                </div>

                <v-layout class="flex-column v-login-info text-left">
                    <h1 class="font-weight-medium mb-2 black-text">{{ $t('forgotPass.forPass') }}</h1>
                    <p class="mt-0">{{ $t('forgotPass.typeEmail') }}</p>
                </v-layout>

                <v-card-text class="py-4 px-0">
                    <p v-if="true">
                        <strong>{{ msg }}</strong>
                    </p>
                    <v-form v-model="valid" ref="form" :action="action" method="POST" role="form">
                        <input type="hidden" name="csrf_token" :value="csrf_token">
                        <div class="field">
                          <label class="black--text mb-2 d-flex">{{ $t('forgotPass.emailAdd') }}</label>
                          <v-text-field
                              placeholder="Enter your e-mail address"
                              v-model="email"
                              name="email"
                              required
                              outlined
                              class="rounded-0"
                              dense
                              :rules="emailRules"
                          ></v-text-field>
                        </div>

                        <v-dialog
                            v-model="dialog"
                            persistent
                            max-width="600px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                    <v-btn type='submit'
                                      block
                                      depressed large
                                      v-bind="attrs"
                                      v-on="off"
                                      class="primary white--text mb-7 rounded-0"
                                      :disabled="!valid"
                                    >{{ $t('forgotPass.submit') }}</v-btn>
                            </template>
                            <v-card class="pa-16">
                                <v-card-text>
                                    <v-container class="text-center">
                                        <v-row justify="center" class="mb-6">
                                            <v-img
                                            max-height="173"
                                            max-width="173"
                                            src="../assets/images/success-check.svg"
                                            ></v-img>
                                        </v-row>
                                        <h2 class="pb-4 font-weight-medium black--text">{{ $t('forgotPass.chckEmail') }}</h2>
                                        <p>{{ $t('forgotPass.para') }}</p>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="justify-center mt-7">
                                    <v-btn
                                        width="164"
                                        color="success"
                                        depressed
                                        large
                                        @click="dialog = false"
                                    >
                                        {{ $t('forgotPass.close') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                    <!-- <a href="/login" class="text-center mb-4">Login Instead</a> -->
                    <p class="mt-3">
                      {{ $t('forgotPass.alreadyAcc') }} <a href="/signin"><u>{{ $t('forgotPass.signIn') }}</u></a>
                    </p>
                </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm7 elevation-0 blue accent-4 class="v-login-right style-2">
            <!-- <v-card flat class="v-login-right-inner">
              <div class="pt-4 white--text">
                <h4 class="font-weight-medium pb-6">{{ $t('changePass.h4') }}</h4>
                <p class="white-text">{{ $t('changePass.para') }}</p>
              </div>
              <v-card-text class="pt-4 px-0 white--text">
                <v-img src="../assets/images/login-img.svg"></v-img>
              </v-card-text>
            </v-card> -->
            <h2>Win every search.</h2>
          </v-flex>
        </v-layout>
    </v-main>
  </div>
</template>

<script>
import Vue from 'vue';
import { FlowService } from '@services/flow.service';

export default Vue.extend({
  name: 'Recovery',
  components: {
  },
    data () {
    return {
      email: "",
      valid:null,
      flow:null,
      action:null,
      csrf_token : null,
      showmsg: false,
      dialog:false,
      msg:"No msg",
      inputEmail: 'Input your E-Mail Id here',
      off: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  methods: {

    async recoverOry() {
    },

    async verifyEmail() {
    },

    buildAction: function (){
        this.msg = "";
        this.flow = this.$route.query.flow;
    },

    goToLogin : function () {
      return this.$router.push('login');
    },

    getRecoveryFlowStatus: async function (){
        await FlowService.flow({ flowId: this.flow, type:'recovery' })
        .then((res) => {
          this.action = res.data.action;
          this.csrf_token = res.data.token;
          if(res.data.state == 'choose_method'){
            this.showmsg = true;
            this.msg='Please enter your registered E-mail Id'
          }
          if(res.data.state == 'sent_email'){
            this.showmsg = true;
            this.msg=res.data.message[0].text;
          }
        })
        .catch((err) => console.log(err));
    }
  },

  beforeMount(){
    this.buildAction();
    this.getRecoveryFlowStatus()
 },
});
</script>
